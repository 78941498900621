import React, { useMemo } from "react";

import { readState } from "@/__main__/app-state.mjs";
import definition from "@/game-eft/definition.mjs";
import type { Profile } from "@/game-eft/models/graphql.mjs";
import getProfileAvatar from "@/game-eft/utils/getProfileAvatar.mjs";
import Accounts from "@/hub-settings/Accounts.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function AccountsEFT() {
  const state = useSnapshot(readState);
  // @ts-ignore Trying to assign the correct types to this causes an error
  const eft: { [accountId: string]: Profile } | undefined =
    state.settings.loggedInAccounts[definition.shortName];
  const profiles = useMemo(
    () =>
      Object.entries(eft ?? {}).map(([, profile]: [string, Profile]) => ({
        displayName: profile.username,
        key: profile.accountId,
        avatar: getProfileAvatar(profile.id),
      })),
    [eft],
  );
  return (
    <Accounts
      profiles={profiles}
      gameShort={definition.shortName}
      autoDetectText={[
        "eft:settings.autoDetectProfile",
        "Hey! I can automatically detect your profile. Just open Escape From Tarkov and log into the account you want to connect.",
      ]}
      // TSX warnings, this is a JSX file being used in a TSX file
      renderOtherActionItems={undefined}
      renderOtherItems={undefined}
    />
  );
}
